import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				RELAXSPA
			</title>
			<meta name={"description"} content={"Вітаємо вас у RelaxSpa, де кожен момент присвячений вашому релаксу та здоров’ю. Наш заклад створений для того, щоб надати вам незабутні відчуття відпочинку і відновлення.\n\n"} />
			<meta property={"og:title"} content={"RELAXSPA"} />
			<meta property={"og:description"} content={"Вітаємо вас у RelaxSpa, де кожен момент присвячений вашому релаксу та здоров’ю. Наш заклад створений для того, щоб надати вам незабутні відчуття відпочинку і відновлення.\n\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6668b161fdcae00021e34089/images/photo-jacuzzi.jpg?v=2024-06-12T08:55:50.619Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="link" />
			<Override slot="box" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text1" />
		</Components.Header23>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6666deca8e2e8e00217cb8c4/images/yoga-tour-carpathians-3.jpg?v=2024-06-10T11:16:53.828Z) center/cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 46px/1.2 --fontFamily-sans" color="--light">
				Послуги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Класичні масажі
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Відчуйте справжнє задоволення від класичних масажів, які допоможуть вам розслабитися та зняти стрес. Наші масажисти знають, як зробити кожну процедуру неповторною.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Ароматерапія
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Зануртеся у світ ароматів та насолоджуйтеся терапевтичними властивостями ефірних олій. Ароматерапія допоможе вам знайти гармонію та поліпшити самопочуття.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Екзотичні процедури
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Спробуйте екзотичні спа-процедури з різних куточків світу. Вони подарують вам нові відчуття та допоможуть відновити сили і енергію.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="2rem 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								Обгортання та скраби
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Доглядайте за своєю шкірою з нашими спеціальними обгортаннями та скрабами. Вони глибоко очищують і зволожують шкіру, роблячи її гладкою та здоровою.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="2rem 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 10px 0px" color="--dark" text-align="center" font="--headline5">
								СПА-дні для двох
							</Text>
							<Text margin="0px 0px 0px 0px" color="--dark" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Проведіть незабутній день разом зі своїм партнером у RelaxSpa. СПА-дні для двох включають різноманітні процедури, які допоможуть вам насолодитися відпочинком разом.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="--color-light"
					border-radius="25px"
					justify-content="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Box
							min-width="100px"
							min-height="100px"
							margin="0px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text margin="0px 0px 0px 0px" color="--dark" font="--headline5" text-align="center">
								Зв’яжіться з нами, щоб дізнатися більше про наші послуги та забронювати візит.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-dark" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				FAQ
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						1. Які послуги пропонує RelaxSpa?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						RelaxSpa пропонує широкий спектр послуг, включаючи класичні масажі, ароматерапію, екзотичні процедури, обгортання та скраби, а також СПА-дні для двох. Детальний список послуг ви можете знайти на нашій сторінці "Послуги".
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						2. Як я можу забронювати візит?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Забронювати візит до RelaxSpa можна, зв’язавшись з нами по телефону або через форму онлайн-бронювання на нашому веб-сайті. Ми рекомендуємо бронювати заздалегідь, щоб забезпечити наявність вільного часу, що вас влаштовує.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						3. Які заходи безпеки ви застосовуєте?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						У RelaxSpa ми дотримуємося всіх необхідних заходів безпеки та гігієни. Ми регулярно дезінфікуємо всі приміщення та обладнання, а наш персонал використовує засоби індивідуального захисту. Всі наші процедури виконуються з урахуванням найвищих стандартів чистоти та безпеки.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						4. Чи можна отримати подарунковий сертифікат?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, у RelaxSpa ви можете придбати подарункові сертифікати на будь-які наші послуги. Це чудовий подарунок для ваших близьких і друзів. Для покупки сертифіката зверніться до нашого адміністратора або скористайтеся нашим веб-сайтом.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						5. Чи можна прийти з дітьми?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						RelaxSpa призначений для дорослих клієнтів, і ми прагнемо забезпечити спокійну та розслаблюючу атмосферу для всіх відвідувачів. Ми рекомендуємо залишити дітей вдома, щоб ви могли повністю насолодитися вашим візитом.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Якщо у вас є інші питання, будь ласка, зв’яжіться з нами. Ми завжди раді допомогти!
						<br />
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer1 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});